import { SelectOption } from '@/components/form/Select/types';
import { LocationProductsDto } from '@/lib/locations/dto/location-products.dto';

export const NB_TOTAL_LOCATIONS = 160;
export const NB_TOTAL_CITIES = 65;
export const GLOBAL_ACCESS_PRICE = 99;
export const ACCESS_YEAR_DISCOUNT_PERCENT = 10;
export const ACCESS_HALF_YEAR_DISCOUNT_PERCENT = 5;
export const LOCALES = ['en', 'es', 'de', 'fr', 'nl'] as const;
export const LOCALE_LABELS = {
  en: 'English',
  es: 'Español',
  de: 'Deutsch',
  fr: 'Français',
  nl: 'Nederlands',
};
export const DEFAULT_LOCALE = 'en';
export const LOCALES_TO_HIDE: string[] = [];

export enum Supplier {
  NUVEEN = 'Nuveen',
}

export const DEFAULT_COUNTRY = 'US';
export const DEFAULT_CURRENCY_ISO_CODE = 'USD';
export const US_GENERIC_NUMBER = '917-421-9701';
export const US_GENERIC_APPLICATION_NUMBER = '917-463-4835';
export const UK_GENERIC_APPLICATION_NUMBER = '+44 7950 948085';
export const US_SUPPORT_PHONE_NUMBER = '917-546-0811';
export const FR_GENERIC_NUMBER = '08 00 94 38 05';
export const LOCALE = 'en-US';

export const PHONE_REGEXP =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
export const STRONG_EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$%\^&\*\(\)_\+\-=\[\]{};':"\\\|,\.<>\/\?])[A-Za-z\d\!\@\#\$%\^&\*\(\)_\+\-=\[\]{};':"\\\|,\.<>\/\?]{8,}$/;

export const MAP_STYLE = 'streets-v12';
export const DEFAULT_MAP_SEARCH_ZOOM = 6;
export const DEFAULT_MAP_LOCATION_PAGE_ZOOM = 15;
export const DEFAULT_MAP_LOCATION_MR_SEARCH_ZOOM = 18;
export const MAP_SEARCH_ADDRESS_ZOOM = 14;
export const MAP_SEARCH_MARKET_NEARBY_ZOOM = 10;
export const MAP_SEARCH_CURRENT_LOCATION = 11;

export const CHECKOUT_PRODUCTS: Array<keyof LocationProductsDto> = ['access'];

export const MEMBER_PORTAL_LOGIN_URL =
  'https://portal.industriousoffice.com/login';

export const DISPLAY_SEASON_AFTER_NB_DAYS = 60;
export const NORTH_SEASONS_BY_QUARTER = [
  '',
  'spring',
  'summer',
  'fall',
  'winter',
];
export const SOUTH_SEASONS_BY_QUARTER = [
  '',
  'fall',
  'winter',
  'spring',
  'summer',
];

// Time constants
export const SECONDS_IN_MS = 1000;
export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_MINUTES = 60;

export const SEATS_OPTIONS: SelectOption[] = [
  {
    label: '1-4',
    value: '1-4',
  },
  {
    label: '5-19',
    value: '5-19',
  },
  {
    label: '20-49',
    value: '20-49',
  },
  {
    label: '50-99',
    value: '50-99',
  },
  {
    label: '100+',
    value: '100+',
  },
];

// Make it last for readability!
export const COOKIE_CONSENT_KEY = 'cookie_consent';
export const EUROPE_COUNTRIES_CODES = [
  'al', // Albania
  'ad', // Andorra
  'at', // Austria
  'by', // Belarus
  'be', // Belgium
  'ba', // Bosnia and Herzegovina
  'bg', // Bulgaria
  'hr', // Croatia
  'cy', // Cyprus
  'cz', // Czech Republic
  'dk', // Denmark
  'ee', // Estonia
  'fi', // Finland
  'fr', // France
  'ge', // Germany
  'de', // Germany
  'gr', // Greece
  'hu', // Hungary
  'is', // Island
  'ie', // Ireland
  'it', // Italy
  'lv', // Latvia
  'li', // Liechtenstein
  'lt', // Lithuania
  'lu', // Luxemburg
  'mk', // North Macedonia
  'mt', // Malta
  'md', // Moldova, Republic of
  'mc', // Monaco
  'me', // Montenegro
  'nl', // Netherland
  'no', // Norway
  'pl', // Poland
  'pt', // Portugal
  'ro', // Romania
  'ru', // Russian Federation
  'sm', // San Marino
  'rs', // Serbia
  'sk', // Slovakia
  'si', // Slovenia
  'es', // Spain
  'se', // Sweden
  'ch', // Switzerland
  'tr', // Turkey
  'ua', // Ukraine
  'gb', // United Kingdom
  'va', // Vatican City
];

export const BROKER_PARTNER_LOGOS = [
  {
    src: '/images/partners/HyattLogo.png',
    alt: 'HYATT Logo',
  },
  {
    src: '/images/partners/LyftLogo.png',
    alt: 'Lyft Logo',
  },
  {
    src: '/images/partners/PfizerLogo.png',
    alt: 'Pfizer Logo',
  },
  {
    src: '/images/partners/SalesforceLogo.png',
    alt: 'Salesforce Logo',
  },
  {
    src: '/images/partners/PivotalLogo.png',
    alt: 'Pivotal Logo',
  },
  {
    src: '/images/partners/SpotifyLogo.png',
    alt: 'Spotify Logo',
  },
  {
    src: '/images/partners/CiscoLogo.png',
    alt: 'Cisco Logo',
  },
  {
    src: '/images/partners/VerizonLogo.png',
    alt: 'Verizon Logo',
  },
  {
    src: '/images/partners/CompassLogo.png',
    alt: 'Compass Logo',
  },
];

export const ENTERPRISE_PARTNER_LOGOS = [
  {
    src: '/images/partners/HyattLogo.png',
    alt: 'HYATT Logo',
  },
  {
    src: '/images/partners/LyftLogo.png',
    alt: 'Lyft Logo',
  },
  {
    src: '/images/partners/PfizerLogo.png',
    alt: 'Pfizer Logo',
  },
  {
    src: '/images/partners/SalesforceLogo.png',
    alt: 'Salesforce Logo',
  },
  {
    src: '/images/partners/PivotalLogo.png',
    alt: 'Pivotal Logo',
  },
  {
    src: '/images/partners/SpotifyLogo.png',
    alt: 'Spotify Logo',
  },
  {
    src: '/images/partners/CiscoLogo.png',
    alt: 'Cisco Logo',
  },
  {
    src: '/images/partners/VerizonLogo.png',
    alt: 'Verizon Logo',
  },
  {
    src: '/images/partners/CompassLogo.png',
    alt: 'Compass Logo',
  },
];

export const LANDLORD_PARTNER_LOGOS = [
  {
    src: '/images/partners/BlackstoneLogo.png',
    alt: 'Blackstone Logo',
  },
  {
    src: '/images/partners/EquinoxLogo.png',
    alt: 'Equinox Logo',
  },
  {
    src: '/images/partners/TaubmanLogo.png',
    alt: 'Taubman Logo',
  },
  {
    src: '/images/partners/HinesLogo.png',
    alt: 'Hines Logo',
  },
  {
    src: '/images/partners/BillingsleyLogo.png',
    alt: 'Billingsley Logo',
  },
  {
    src: '/images/partners/BeaconLogo.png',
    alt: 'Beacon Logo',
  },
  {
    src: '/images/partners/MacerichLogo.png',
    alt: 'Macerich Logo',
  },
  {
    src: '/images/partners/EQofficeLogo.png',
    alt: 'EQoffice Logo',
  },
  {
    src: '/images/partners/JamestownLogo.png',
    alt: 'Jamestown Logo',
  },
  {
    src: '/images/partners/BrookfieldLogo.png',
    alt: 'Brookfield Logo',
  },
];

export const SUPPLIER_LOGOS = {
  Nuveen: {
    src: '/images/NuveenLogo.webp',
    alt: 'Nuveen Logo',
  },
  Industrious: {
    src: '/images/IndustriousLogoBlack.webp',
    alt: 'Industrious Logo',
  },
};

export const NAVBAR_HEIGHT = 70;
export const NAVBAR_HEIGHT_MOBILE = 48;

export const SEARCH_ROUTES = {
  MEETING_ROOMS: 'meeting-rooms',
  LOCATIONS: 'locations',
  COWORKING: 'coworking',
};

export const FALLBACK_MEETING_ROOM_EMAIL = 'meetings@industriousoffice.com';

export const DEFAULT_TIMEZONE = 'America/New_York';

export const ID_MODAL_CONTAINER = 'modal-root';
export const ID_CALENDLY_CONTAINER = 'calendly-modal-root';
export const ID_BOTTOM_MENU_CONTAINER = 'bottom-menu-root';
export const ID_PAGE_CONTAINER = 'page';

export const RETRY_DELAY = 3000;
export const ONE_DAY = 1;

export const RESERVATION_CANCELLATION_LIMIT_HOURS = 24;

export const RESPONSE_FROM_SUBMIT_ZUORA = 'Response_From_Submit_Page';
export const DELTA_CHECKOUT_FORM = 'delta-checkout';
