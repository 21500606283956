import { useTranslations } from 'next-intl';

import { SelectOption } from '@/components/form/Select/types';
import {
  LocationDto,
  SimpleIndustriousLocationSearchDto,
  SimpleLocationSearchDto,
  WorlwideExternalLocationDto,
} from '@/lib/locations/dto';
import { LocationPhases, ManagerRoles } from '@/lib/locations/dto';
import {
  LocationOpeningHoursDto,
  LocationValueOpeningHour,
} from '@/lib/locations/dto/location-opening-hours.dto';
import { BlackoutDateDto } from '@/lib/shared/dto/blackout-date.dto';
import { BrandNames } from '@/models/BrandNames.enum';
import { DateRange } from '@/models/DateRange';
import tgrLogo from '@/public/images/brands/TGR.svg';
import wmLogo from '@/public/images/brands/welkin-and-meraki.svg';

import {
  DISPLAY_SEASON_AFTER_NB_DAYS,
  NORTH_SEASONS_BY_QUARTER,
  SOUTH_SEASONS_BY_QUARTER,
} from './constants';
import {
  differenceInDays,
  formatToDay,
  getAbbreviatedDayName,
  getNextDay,
  getQuarter,
  getTomorrowDay,
} from './date-utils';
import { sortByLabel } from './select-option-utils';
import { stringifyUrl } from './stringify-url';
import { stringDay } from './validators/string-day-validator';

export function createLocationUrl(location: {
  isExternal?: boolean;
  externalLink?: string;
  slug: string;
}) {
  return location.isExternal
    ? location.externalLink!
    : `/locations/${location.slug}`;
}

export function createLocationDayPassUrl(params: {
  slug: string;
  dateRange?: DateRange;
}) {
  const { slug, dateRange } = params;

  const url = `/locations/${slug}/coworking-day-pass`;

  if (!dateRange) return url;

  return stringifyUrl(url, {
    startDay: formatToDay(dateRange.from),
    endDay: formatToDay(dateRange.to),
  });
}

export function isIndustriousLocation(
  location: SimpleLocationSearchDto
): location is SimpleIndustriousLocationSearchDto {
  return !location.isExternal;
}

export function isExternalLocation(
  location: SimpleLocationSearchDto
): location is WorlwideExternalLocationDto {
  return location.isExternal;
}

export function getBrandLogo(brandName: string | null) {
  switch (brandName) {
    case BrandNames.WelkinMeraki:
      return wmLogo;
    case BrandNames.TheGreatRoom:
      return tgrLogo;
    default:
      return undefined;
  }
}

type LocationForOption = Pick<LocationDto, 'sfId' | 'name' | 'city'> & {
  metroName?: string;
};

export const mapLocationsToOptions = (
  locations: Array<LocationForOption>
): SelectOption[] => locations.map(mapLocationToOption).sort(sortByLabel);

export const mapLocationToOption = (
  location: LocationForOption
): SelectOption => ({
  label: getLocationOptionLabel(location),
  value: location.sfId,
});

const getLocationOptionLabel = (location: LocationForOption) => {
  return `${location.city} - ${location.name}`;
};

// This function extract the date disregarding time and timezone as we currently don't care in SF.
// if we don't do this, it brings an issue
export function getOpeningDate(dateISO: string) {
  const year = +dateISO.substring(0, 4);
  const month = +dateISO.substring(5, 7) - 1;
  const day = +dateISO.substring(8, 10);

  return new Date(year, month, day);
}

export function getOpeningLabelTranslationKey(params: {
  openingDate: Date;
  latitude: number;
}) {
  const { openingDate, latitude } = params;

  const is60daysAway =
    differenceInDays(openingDate, new Date()) > DISPLAY_SEASON_AFTER_NB_DAYS;

  if (!is60daysAway) {
    return 'date';
  }

  const quarter = getQuarter(openingDate);

  return latitude > 0
    ? NORTH_SEASONS_BY_QUARTER[quarter]
    : SOUTH_SEASONS_BY_QUARTER[quarter];
}

export function managerRoleToTranslationKey(role: string) {
  switch (role) {
    case ManagerRoles.OperationsAssociate:
      return 'operations-associate';
    case ManagerRoles.ConferenceServicesManager:
      return 'conference-services-manager';
    case ManagerRoles.TechnicalAudioVisualManager:
      return 'technical-audio-visual-manager';
    default:
      return 'community-manager';
  }
}

export function shouldShowLogo(brandName: string | null) {
  return brandName === BrandNames.WelkinMeraki;
}

export function getLocationHighlightName(params: {
  name: string;
  brandName: LocationDto['brandName'];
  t: ReturnType<typeof useTranslations<string>>;
}) {
  const { name, brandName, t } = params;

  if (
    brandName == null ||
    brandName === BrandNames.WelkinMeraki ||
    name.includes(brandName)
  ) {
    return name;
  }

  return t('highlight-name', { brandName, locationName: name });
}

export function shouldShowOpeningLabel(location: {
  isExternal?: boolean;
  firstDayOfBusinessISO: string | null;
  phase?: LocationPhases;
  hideOpeningDate?: boolean;
  isWaitlisting?: boolean;
}) {
  return (
    !location.isExternal &&
    location.firstDayOfBusinessISO != null &&
    !location.hideOpeningDate &&
    location.phase != null &&
    !location.isWaitlisting &&
    [
      LocationPhases.Signed,
      LocationPhases.Launch,
      LocationPhases.PostLaunch,
    ].includes(location.phase)
  );
}

export const getDayOpeningHours = (
  openingHours: LocationOpeningHoursDto,
  day: string
): LocationValueOpeningHour | null => {
  const currentDayOfWeek = getAbbreviatedDayName(
    day
  ) as keyof LocationOpeningHoursDto;

  return openingHours[currentDayOfWeek] ?? null;
};

export function getNextAvailableDay(props: {
  day: string;
  blackoutDates: BlackoutDateDto[];
  openingHours: LocationOpeningHoursDto;
  unavailableDays: string[];
}) {
  const { day, blackoutDates, openingHours, unavailableDays } = props;
  let isAvailableDay = false;
  let currentDay = day;

  while (!isAvailableDay) {
    const dayOpeningHours = getDayOpeningHours(openingHours, currentDay);
    if (
      dayOpeningHours === null ||
      blackoutDates.some((blackoutDate) =>
        isBlackoutDay(currentDay, blackoutDate)
      ) ||
      unavailableDays.includes(currentDay)
    ) {
      currentDay = getNextDay(currentDay);
      continue;
    }
    isAvailableDay = true;
  }

  return currentDay;
}

export function getValidEndDays(props: {
  startDay: string;
  blackoutDates: BlackoutDateDto[];
  openingHours: LocationOpeningHoursDto;
  unavailableDays: string[];
}) {
  const { startDay, blackoutDates, openingHours, unavailableDays } = props;
  let currentDay = startDay;
  const possibleEndDays: string[] = [startDay];

  while (true) {
    const nextDay = getNextDay(currentDay);
    const nextAvailableDay = getNextAvailableDay({
      day: nextDay,
      blackoutDates,
      openingHours,
      unavailableDays,
    });
    if (nextAvailableDay !== nextDay) {
      break;
    }
    possibleEndDays.push(nextDay);
    currentDay = nextDay;
  }

  return possibleEndDays;
}

export function getMaxValidEndDay(
  props: Parameters<typeof getValidEndDays>[0]
) {
  const validEndDays = getValidEndDays(props);
  return validEndDays[validEndDays.length - 1];
}

function isBlackoutDay(day: string, blackoutDate: BlackoutDateDto) {
  const blackoutDay = blackoutDate.date.split('T')[0];
  return day === blackoutDay;
}

export async function getNextAvailableDateRange(props: {
  location: LocationDto;
  preferredStartDay?: string;
  preferredEndDay?: string;
  unavailableDays: string[];
}): Promise<{ startDay: string; endDay: string }> {
  const { location, preferredEndDay, preferredStartDay, unavailableDays } =
    props;

  const startDay = getNextAvailableDay({
    day: await getValidDay(preferredStartDay),
    openingHours: location.openingHours,
    blackoutDates: location.blackoutDates,
    unavailableDays,
  });

  if (
    startDay !== preferredStartDay ||
    !preferredEndDay ||
    preferredEndDay < preferredStartDay
  ) {
    return getDefaultDateRange(startDay);
  }

  const endDay = getNextAvailableDay({
    day: await getValidDay(preferredEndDay),
    openingHours: location.openingHours,
    blackoutDates: location.blackoutDates,
    unavailableDays,
  });

  if (endDay !== preferredEndDay) {
    return getDefaultDateRange(startDay);
  }

  const validEndDates = await getValidEndDays({
    startDay,
    blackoutDates: location.blackoutDates,
    openingHours: location.openingHours,
    unavailableDays,
  });

  if (validEndDates.some((x) => x === endDay)) {
    return { startDay, endDay };
  }

  return getDefaultDateRange(startDay);
}

async function getValidDay(day: string | undefined): Promise<string> {
  const tomorrowDay = getTomorrowDay();

  if (day == null) {
    return tomorrowDay;
  }

  const isValid = await stringDay().isValid(day);

  if (!isValid || day < tomorrowDay) {
    return tomorrowDay;
  }
  return day;
}

function getDefaultDateRange(startDay: string) {
  return { startDay, endDay: startDay };
}

export function isCoworkingDayPassEnabled(location: LocationDto) {
  const {
    canBuyOnline,
    availableAtLocation,
    dailyOnDemandCapacity,
    currentlySellable,
  } = location.products.coworkingDayPass;

  return Boolean(
    canBuyOnline &&
      currentlySellable &&
      availableAtLocation &&
      dailyOnDemandCapacity != null &&
      dailyOnDemandCapacity > 0
  );
}
